import type { Country } from '~/types/Country'

export const countries: Array<Country> = [
  {
    id: '1',
    name: 'Austria',
    partners: [
      {
        id: 'e3063fbb-fe81-46f2-92f3-af5acb5fbcc5',
        name: 'foodora',
        domain: 'mjam',
        image_url: '/images/foodora.webp'
      },
      {
        id: 'bfb65bc4-9d39-41af-b1b1-34cb66efccc7',
        name: 'No Partner',
        domain: 'austria',
        image_url: 'images/cycle_logo.svg'
      }
    ]
  },
  {
    id: '2',
    name: 'Germany',
    partners: [
      {
        id: 'd116be5e-152d-4cd6-bc37-95ef2a93bf1b',
        name: 'Fleetfox',
        domain: 'FleetFox_Berlin_Runzheimer',
        image_url: 'images/fleetfox.png'
      },
      {
        id: 'e802da2d-67da-4cbd-9fcd-ff016deaa313',
        name: 'No Partner',
        domain: 'germany',
        image_url: 'images/cycle_logo.svg'
      },
      {
        id: 'cceafbde-330e-4454-aa22-979bb7f3270a',
        name: 'RadPower',
        domain: 'radpowerbikes',
        image_url: '',
        visible: false
      }
    ]
  },
  {
    id: '3',
    name: 'Italy',
    partners: [
      {
        id: '08162a2c-bea3-468e-a14d-e73961d071f8',
        name: 'No Partner',
        domain: 'italy',
        image_url: 'images/cycle_logo.svg'
      }
    ]
  },
  {
    id: '4',
    name: 'Netherlands',
    partners: [
      {
        id: '0988068c-76a5-4c3e-827a-57e04fbfb75b',
        name: 'No Partner',
        domain: 'netherlands',
        image_url: 'images/cycle_logo.svg'
      }
    ]
  }
  // {
  //   id: '4',
  //   name: 'United Kingdom',
  //   partners: []
  // }
]
